import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  ElementRef,
  SimpleChanges,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  NgZone
} from '@angular/core';
import Popper from 'popper.js';

@Component({
  selector: 'angular-popper',
  template: `<ng-content></ng-content>

<div class="angular-popper"
     [class.visible]="show">
  <button type="button"
          class="angular-popper__close"
          *ngIf="closeButton"
          (click)="onClose()">
    <ng-content select=".close-button, [close-button]"></ng-content>
  </button>

  <ng-content select=".content, [content]"></ng-content>

  <div class="angular-popper__arrow" x-arrow></div>
</div>
`,
  styles: [`.angular-popper{position:absolute;background:#ffc107;color:#000;width:150px;border-radius:3px;box-shadow:0 0 2px rgba(0,0,0,.5);padding:10px;text-align:center}.angular-popper:not(.visible){display:none}.angular-popper .angular-popper__arrow{width:0;height:0;border-style:solid;border-color:#ffc107;position:absolute;margin:5px}.angular-popper[x-placement^=top]{margin-bottom:5px}.angular-popper[x-placement^=top] .angular-popper__arrow{border-width:5px 5px 0;border-left-color:transparent;border-right-color:transparent;border-bottom-color:transparent;bottom:-5px;left:calc(50% - 5px);margin-top:0;margin-bottom:0}.angular-popper[x-placement^=bottom]{margin-top:5px}.angular-popper[x-placement^=bottom] .angular-popper__arrow{border-width:0 5px 5px;border-left-color:transparent;border-right-color:transparent;border-top-color:transparent;top:-5px;left:calc(50% - 5px);margin-top:0;margin-bottom:0}.angular-popper[x-placement^=right]{margin-left:5px}.angular-popper[x-placement^=right] .angular-popper__arrow{border-width:5px 5px 5px 0;border-left-color:transparent;border-top-color:transparent;border-bottom-color:transparent;left:-5px;top:calc(50% - 5px);margin-left:0;margin-right:0}.angular-popper[x-placement^=left]{margin-right:5px}.angular-popper[x-placement^=left] .angular-popper__arrow{border-width:5px 0 5px 5px;border-top-color:transparent;border-right-color:transparent;border-bottom-color:transparent;right:-5px;top:calc(50% - 5px);margin-left:0;margin-right:0}`],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopperComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() show = true;
  @Input() closeButton = false;
  @Input() placement: Popper.Placement = 'bottom';
  @Input() positionFixed = false;
  @Input() eventsEnabled = true;
  @Input() modifiers: Popper.Modifiers;
  @Input() target: string | Element;

  @Output() close = new EventEmitter();

  private popper: Popper;

  constructor(private el: ElementRef, private zone: NgZone) {}

  ngAfterViewInit() {
    this.create();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.target && !changes.target.firstChange ||
      changes.placement && !changes.placement.firstChange ||
      changes.positionFixed && !changes.positionFixed.firstChange ||
      changes.eventsEnabled && !changes.eventsEnabled.firstChange
    ) {
      this.destroy();
      this.create();
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  onClose() {
    this.show = false;
    this.close.emit();
  }

  create() {
    this.zone.runOutsideAngular(() => {
      const { placement, positionFixed, eventsEnabled, modifiers } = this;

      this.popper = new Popper(
        this.getTargetNode(),
        this.el.nativeElement.querySelector('.angular-popper'),
        {
          placement,
          positionFixed,
          eventsEnabled,
          modifiers
        }
      );
    });
  }

  destroy() {
    if (this.popper) {
      this.zone.runOutsideAngular(() => {
        this.popper.destroy();
      });

      this.popper = null;
    }
  }

  private getTargetNode(): Element {
    if (this.target) {
      if (typeof this.target === 'string') {
        return document.querySelector(this.target);
      } else {
        return this.target;
      }
    } else {
      return this.el.nativeElement;
    }
  }
}
